$black: #000;
$white: #fff;
$bisque: #fcf3e0;
$purple: #851b59;
$yellow: #fcb316;
$yellow-200: #fae9c8;
$orange: #f9902d;
$active-link: #005799;
$table-hover: #f0f5fa;
$red-200: #fae1e5;
$red-300: #e07f82;
$red-600: #cc0022;
$gray-100: #f7f7f7;
$gray-200: #d7d7d7;
$gray-400: #b8b8b8;
$gray-600: #808080;
$gray-700: #4d4d4d;
$gray-800: #333333;
$gray-900: #131318;
$green-200: #dcf2dc;
$green-300: #6eb86e;
$green-600: #187a18;
$blue-200: #dcedf5;
$blue-300: #abc8d6;
$blue-400: #7ab1cc;
$blue-600: #1f7099;
$white-200: #ebebeb;

$blue: #0f62c6;
$blue-link: #0f62fe;
$gray: #f1f1f1;
$dark-grey: #838388;

$active-table-selected: #dcebfa;
$active-table-sub-rows: #edf5fc;

$shadow-table-expanded: #ebebeb;
$basic-foreground: #2f2f2f;

$nunito: "Nunito Sans", sans-serif;
$primary-font: $nunito;

$regular: 400;
$semi-bold: 600;
$bold: 700;

$font-size-base: 14px;
$font-size-medium: 20px;
$font-size-big: 24px;
$line-height-base: calc(20 / 14);

$brs: 4px;
$trs: 0.3s;

$small-table-border: #f0f0f0;

$backgroundMain: #fff;

// --- menu color

$primary: $blue-link;
$red-200-hover: #f2dade;
$selected-hover: #d5e4f2;
$gray-100-hover: #efefef;
