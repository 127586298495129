@import "./variables.scss";
@import "./reset.scss";
@import "./scroll.scss";
@import "./react-select.scss";
@import "./react-datepicker.scss";
@import "./documents.page.scss";
@import "./btn.scss";
@import "./wrappers.scss";
@import "./loader.scss";
@import "./tables.scss";
@import "./header.scss";
@import "./menu-sidebar.scss";
@import "./app.scss";
@import "./login.scss";
@import "./chatAdmin.scss";
@import "./messages.scss";
@import "./version.page.scss";
@import "./breadcrumbs.scss";
@import "./doc_md_contentю.scss";
@import "./settings.scss";
@import "./prompt.scss";
@import "./session-chat.scss";

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-SemiBold.eot");
  src:
    url("../fonts/NunitoSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-SemiBold.woff2") format("woff2"),
    url("../fonts/NunitoSans-SemiBold.woff") format("woff"),
    url("../fonts/NunitoSans-SemiBold.ttf") format("truetype"),
    url("../fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-Bold.eot");
  src:
    url("../fonts/NunitoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-Bold.woff2") format("woff2"),
    url("../fonts/NunitoSans-Bold.woff") format("woff"),
    url("../fonts/NunitoSans-Bold.ttf") format("truetype"),
    url("../fonts/NunitoSans-Bold.svg#NunitoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-SemiBoldItalic.eot");
  src:
    url("../fonts/NunitoSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/NunitoSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/NunitoSans-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-BoldItalic.eot");
  src:
    url("../fonts/NunitoSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/NunitoSans-BoldItalic.woff") format("woff"),
    url("../fonts/NunitoSans-BoldItalic.ttf") format("truetype"),
    url("../fonts/NunitoSans-BoldItalic.svg#NunitoSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-Italic.eot");
  src:
    url("../fonts/NunitoSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-Italic.woff2") format("woff2"),
    url("../fonts/NunitoSans-Italic.woff") format("woff"),
    url("../fonts/NunitoSans-Italic.ttf") format("truetype"),
    url("../fonts/NunitoSans-Italic.svg#NunitoSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-Regular.eot");
  src:
    url("../fonts/NunitoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-Regular.woff2") format("woff2"),
    url("../fonts/NunitoSans-Regular.woff") format("woff"),
    url("../fonts/NunitoSans-Regular.ttf") format("truetype"),
    url("../fonts/NunitoSans-Regular.svg#NunitoSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  min-height: 320px;
}

body {
  font-family: $nunito;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $regular;
  color: $black;
  min-width: 1200px;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.show-info {
  display: flex;
  flex-direction: row !important;

  .wrapper-column__main {
    width: calc(100% - 580px);
    max-width: calc(100% - 580px);
    padding-right: 16px;
  }
}

h1 {
  font-size: 24px;
  line-height: calc(34 / 24);
  font-weight: $regular;
}

h2 {
  font-size: 20px;
  line-height: calc(28 / 20);
  font-weight: $bold;
}

a {
  transition: color $trs ease;
  will-change: color;
  color: $black;

  &:hover {
    color: $active-link;
    text-decoration: none;
  }
}

.wrapper-input {
  display: block;
  width: 100%;
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    color: rgba($black, 0.65);

    &:last-child {
      margin: 0;
    }
  }

  textarea,
  input {
    display: block;
    width: 100%;
    background: $white;
    border: 1px solid $gray-400;
    border-radius: 4px;
    height: 36px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: rgba($black, 0.9);
    margin: 4px 0 8px;
    padding: 8px;
    outline: none;
    appearance: none;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::-webkit-input-placeholder {
      color: rgba($black, 0.5);
      opacity: 1;
    }

    &::-moz-placeholder {
      color: rgba($black, 0.5);
      opacity: 1;
    }

    &:-moz-placeholder {
      color: rgba($black, 0.5);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: rgba($black, 0.5);
      opacity: 1;
    }

    &:active,
    &:focus {
      border-color: $black;
      box-shadow: 0px 0px 0px 1px $black;
    }

    &.error {
      border-color: $red-600 !important;

      &:focus {
        border-color: $black;
        box-shadow: 0px 0px 0px 1px $red-600;
      }
    }

    &:disabled,
    &[readOnly] {
      border-color: $gray-400;
      box-shadow: none;
      opacity: 0.75;
      cursor: not-allowed;
      background: $gray-100;
    }
  }

  textarea {
    min-height: 216px;
    resize: vertical;
  }

  span.error {
    color: $red-600;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    width: calc(100% - 18px);
    margin: 4px 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .toggle_pass {
    margin: 4px 0;
    position: relative;
    min-height: 36px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .eye {
      display: none;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 9px;
      top: 50%;
      z-index: 1;
      cursor: pointer;
      margin: -8px 0 0;
      padding: 0;
      background: transparent url("../svg/eye.svg") no-repeat scroll 0 0;
      background-size: 16px 32px;

      &:hover {
        opacity: 0.5;
      }
    }

    input:not(:placeholder-shown) {
      padding-right: 32px;
    }

    input:not(:placeholder-shown) + .eye {
      display: block;
    }

    input[data-empty="true"] + .eye {
      display: none;
    }

    input[type="text"] + .eye {
      background-position: 0 0;
      background-size: 16px 32px;
    }

    input[type="password"] + .eye {
      background-position: 0 -16px;
      background-size: 16px 32px;
    }
  }
}

.wrapper-tag-input {
  span.error {
    width: calc(100% - 18px);
    margin: 4px 9px;
  }
}

.wrapper-select {
  display: block;
  width: 100%;
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    color: rgba($black, 0.65);

    &:last-child {
      margin: 0;
    }
  }

  p {
    display: block;
    width: 100%;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    margin: 4px 0;
    opacity: 0.65;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  span.error {
    color: $red-600;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    width: calc(100% - 18px);
    margin: 4px 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrapper-checkbox {
  padding: 10px 8px;
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    max-width: 100%;
    padding: 0 0 0 24px;
    margin: 0;
    cursor: pointer;

    i,
    input {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      opacity: 0 !important;
      z-index: -1;
      max-width: 0;
      max-height: 0;
    }

    span {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid $gray-400;
        background: $white;
        border-radius: 2px;
        position: absolute;
        top: 2px;
        left: 0;
        padding: 0;
        margin: 0;
      }
    }

    i.checked + span,
    input:checked + span {
      &::before {
        border: 0 none;
        background: $primary url("../svg/check_mark.svg") no-repeat scroll 0 0;
        background-size: 16px 32px;
      }
    }

    input:active + span,
    input:focus-visible + span {
      &::before {
        box-shadow: 0px 0px 0px 1px $black;
        border-color: $black;
      }
    }

    input:indeterminate + span {
      &::before {
        border: 0 none;
        background: $primary url("../svg/check_mark.svg") no-repeat scroll 0 -16px;
        background-size: 16px 32px;
      }
    }

    i.disabled + span,
    input:disabled + span {
      opacity: 0.25 !important;
    }
  }

  &.wrapper-toggle-checkbox {
    label {
      padding: 0 0 0 36px;

      span {
        &::before {
          content: "";
          display: block;
          width: 28px;
          height: 16px;
          border: 0 none;
          background: $gray-200;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 0;
          padding: 0;
          margin: -8px 0 0;
          z-index: 1;
        }

        &::after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background: $white;
          border-radius: 6px;
          position: absolute;
          top: 50%;
          left: 2px;
          padding: 0;
          margin: -6px 0 0;
          z-index: 2;
          border: 1px solid $shadow-table-expanded;
        }
      }

      input:checked + span {
        &::before {
          background: $primary;
        }

        &::after {
          left: 14px;
        }
      }
    }
  }
}

.wrapper-switcher {
  display: flex;
  margin: 16px 0;
  padding: 0;

  > label {
    position: relative;
    display: inline-block;
    margin: 0;
    min-height: 20px;
    cursor: pointer;

    > input {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -1;
      max-width: 0;
      max-height: 0;
    }

    > span {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      overflow: hidden;
      margin: 0 0 0 36px;

      &::before {
        content: "";
        display: block;
        width: 28px;
        height: 16px;
        border-radius: 20px;
        background: $gray-200;
        position: absolute;
        top: 50%;
        margin: -8px 0 0;
        left: 0;
        padding: 2px;
      }

      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $white;
        position: absolute;
        z-index: 2;
        border: 1px solid $gray-100;
        top: 50%;
        margin: -6px 0 0;
        left: 2px;
      }
    }

    input:checked + span {
      &::before {
        background: $primary;
        border: none;
      }

      &::after {
        transform: translateX(100%);
      }
    }
  }
}

.wrapper-checkbox.new-users {
  padding: 8px 0;
}

.prefix-info {
  display: block;
  width: 100%;
  padding: 0;
  margin: 4px 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
  color: rgba($black, 0.65);
}

.safe {
  display: inline-block;
  vertical-align: top;
  width: auto;
  min-height: 16px;
  max-width: 100%;
  position: relative;
  padding: 0 0 0 24px;

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    margin: -8px 0 0;
    padding: 0;
    background: transparent url("../svg/safe.svg") no-repeat scroll 0 0;
  }
}

.alert-inline {
  display: block;
  width: 100%;
  padding: 8px 20px 8px 52px;
  position: relative;
  min-height: 36px;
  margin: 16px 0;

  max-height: 130px;
  overflow-y: auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: 400;
    color: $black;

    &.bold {
      font-weight: 700;
    }
  }

  &.error {
    background: $red-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMzEzNyAwSDQuNjg2MjlMMCA0LjY4NjI5VjExLjMxMzdMNC42ODYyOSAxNkgxMS4zMTM3TDE2IDExLjMxMzdWNC42ODYyOUwxMS4zMTM3IDBaTTQgNS41TDYuNSA4TDQgMTAuNUw1LjUgMTJMOCA5LjVMMTAuNSAxMkwxMiAxMC41TDkuNSA4TDEyIDUuNUwxMC41IDRMOCA2LjVMNS41IDRMNCA1LjVaIiBmaWxsPSIjQ0MwMDIyIi8+DQo8L3N2Zz4NCg==)
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.success {
    background: $green-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent url("../svg/alert_error_success.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.likeIcon {
    background: $green-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent url("../svg/likeIcon.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.info {
    background: $blue-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent url("../svg/alert_info_icon.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.warning {
    background: $yellow-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 9px;
      left: 20px;
      background: transparent url("../svg/alert_warning_ico.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }
}

.max-width-536 {
  max-width: 536px;
}

.wrapper-form {
  width: 100%;
  margin: 32px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    display: block;
    overflow: hidden;
    width: 100%;
    margin: 0 0 16px;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  .flex-two-col-160 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: auto;
    margin: 16px -8px;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .wrapper-input {
      label {
        margin: 0 0 4px;
      }
    }

    > div:nth-child(odd) {
      padding: 0 8px;
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      width: calc(100% - 176px);
      max-width: calc(100% - 176px);
    }

    > div:nth-child(even) {
      padding: 0 8px;
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: 176px;
      max-width: 176px;
    }

    &.flex-two-col-82 {
      > div:nth-child(odd) {
        width: calc(100% - 98px);
        max-width: calc(100% - 98px);
      }

      > div:nth-child(even) {
        width: 98px;
        max-width: 98px;
      }
    }
  }
}

.popover-container {
  width: 360px;
  border: 1px solid $gray-200;
  border-radius: 4px;
  background: $white;
  padding: 8px 16px;
  font-family: $nunito;
  box-shadow: 0px 6px 24px -6px rgba($black, 0.2);

  p {
    display: block;
    font-weight: 700;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $gray-600;
    margin: 0 0 8px;
    padding: 0;
    overflow: hidden;
  }

  ul {
    border-bottom: 1px solid $gray-200;
    margin: 0 0 8px;
    padding: 0;
    display: block;
    list-style: none;

    > li {
      display: block;
      padding: 10px 0;
      margin: 0;
    }
  }

  .flex-container {
    position: relative;
    margin: 0 -4px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;

    > div {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      width: 50%;
      max-width: 50%;
      padding: 0 4px;

      .wrapper-checkbox {
        margin: 0;
        padding: 0;
      }

      p {
        font-weight: 400;
        color: $black;
        margin: 0;
      }
    }
  }
}

.wrapper-radio-box {
  padding: 10px 8px;
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    max-width: 100%;
    padding: 0 0 0 24px;
    margin: 0;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      opacity: 0 !important;
      z-index: -1;
      max-width: 0;
      max-height: 0;
    }

    span {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid $gray-400;
        background: $white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 0;
        padding: 0;
        margin: 0;
      }
    }

    input:checked + span {
      &::before {
        border: 4px solid $primary;
        background: $white;
      }
    }

    input:active + span,
    input:focus-visible + span {
      &::before {
        box-shadow:
          0px 0px 0px 1px $black,
          0px 0px 0px 1px $black inset;
      }
    }

    input:disabled + span {
      opacity: 0.25 !important;
    }

    input.error + span {
      &::before {
        border-color: $red-600;
      }
    }
    input.error:checked + span {
      &::before {
        border-color: $red-200;
        background: $red-600;
      }
    }

    input.error:active + span,
    input.error:focus-visible + span {
      &::before {
        box-shadow:
          0px 0px 0px 1px $red-600,
          0px 0px 0px 1px $red-600 inset;
      }
    }
  }
}

.wrapper-file-upload {
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    color: rgba($black, 0.65);

    &:last-child {
      margin: 0;
    }
  }

  p {
    display: block;
    width: auto;
    max-width: calc(100% - 18px);
    padding: 0;
    margin: 4px 9px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;

    &:first-child {
      margin: 0;
    }
  }

  .file {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: auto;
    max-width: calc(100% - 18px);
    margin: 8px 9px;

    > div:nth-child(1) {
      width: 114px;
      max-width: 114px;
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }

    > div:nth-child(2) {
      width: calc(100% - 114px);
      max-width: calc(100% - 114px);
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
    }

    label {
      width: 114px;
      height: 36px;
      margin: 0;
      padding: 0;
      position: relative;
      cursor: pointer;

      input {
        opacity: 0;
        width: 100%;
        height: 100%;
      }

      .button {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        display: block;
        transition: background-color $trs ease;
        will-change: background-color;
        padding: 7px;
        border-radius: $brs;
        font-weight: $bold;
        color: $black;
        border: 1px solid $gray-200;
        background-color: $white;
        font-size: 14px;
        line-height: 20px;
        max-width: 100%;
        text-align: center;
        width: 114px;
        height: 36px;
        user-select: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover {
        .button {
          background-color: $gray-100;
        }
      }

      input:focus ~ .button {
        border: 1px solid $gray-200;
        box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
        background-color: $white;
        outline: none;
      }

      input:disabled ~ .button {
        opacity: 0.5;
        cursor: not-allowed !important;
        pointer-events: none !important;
        background: $white;
      }

      &.error {
        .button {
          border-color: $red-600;
        }

        input:focus ~ .button {
          border-color: $red-600;
          box-shadow: 0px 0px 0px 2px rgba($red-600, 0.2);
        }
      }
    }

    .file-custom-name {
      display: block;
      width: 100%;
      height: 100%;
      user-select: none;
      padding: 8px 0 8px 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $gray-600;
    }
  }

  span.error {
    color: $red-600;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    width: calc(100% - 18px);
    margin: 4px 9px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.password-hint {
  margin: 0 9px;
  width: auto;
  max-width: calc(100% - 18px);

  p {
    display: block;
    width: 100%;
    overflow: hidden;
    color: $gray-600;
    font-weight: 200;
    position: relative;
    padding: 0 24px;
    font-size: 14px;
    line-height: 20px;
    margin: 4px 0 0;
    min-height: 20px;

    > span {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    &.success {
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0;
        position: absolute;
        margin: 0;
        padding: 0;
        background: transparent url("../svg/alert_error_success.svg") no-repeat scroll 0 0;
      }
    }
    &.error {
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0;
        position: absolute;
        margin: 0;
        padding: 0;
        background: transparent url("../svg/xCircle.svg") no-repeat scroll 0 0;
      }
    }
  }
}

.item-clip {
  word-break: break-all !important;
}

body > textarea,
body > .textAreaCopyToClipboard {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

.progress-widget {
  margin: 0 0 16px;
  padding: 0;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  ul.legend {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      margin: 0;
      padding: 4px 8px;
      list-style: none;
      width: auto;
      max-width: 33.33333333333333%;
      p {
        display: block;
        margin: 0;
        padding: 0 0 0 20px;
        position: relative;
        width: 100%;
        min-height: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        &::before {
          content: "";
          display: block;
          margin: 0;
          padding: 0;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 4px;
          left: 0;
        }
        > span {
          display: block;
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
          min-height: 20px;
        }
      }
      &.other {
        p {
          &::before {
            background: $blue-600;
          }
        }
      }
      &.this {
        p {
          &::before {
            background: $blue-400;
          }
        }
      }
      &.available {
        p {
          &::before {
            border: 1px solid $gray-600;
            background: $blue-200;
          }
        }
      }
      &.overcommitted {
        p {
          &::before {
            background: $red-300;
          }
        }
      }
    }
  }
  .progress-bar {
    width: 100%;
    height: 6px;
    padding: 0;
    margin: 0 0 8px;
    background: $blue-200;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    &.overcommitted {
      background: $red-300;
    }
    > span {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: auto;
      max-width: 100%;
      height: 6px;
      &.other {
        background: $blue-600;
      }
      &.this {
        background: $blue-400;
      }
    }
  }
}

.wrapper-ddl-view {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;

  > .ddl-view-content {
    padding: 6px 108px 6px 12px;
    background: $gray-100;
    font-size: 14px;
    line-height: 20px;
    font-family: monospace, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;

    > div {
      padding: 6px;
      margin: 0;
      white-space: pre;
      overflow: auto;
      flex: 1 1 auto;
    }
  }

  > button {
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background: transparent;
    box-shadow: none;
    width: 90px;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.wrapper-alert-inline {
  &.marin-top-0 {
    margin-top: 0 !important;
  }
}

// === ResizeObserver loop limit exceeded' ===

.hide-resize-observer {
  display: none !important;
}

.data-row-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  gap: 16px;

  border-color: $gray-200;
  border-style: solid;
  border-width: 1px 0 0 1px;

  // ---

  position: fixed;
  z-index: 1040;
  top: 56px;
  bottom: 0;
  left: 600px;
  padding: 20px;
  width: 600px;
  min-width: 600px;
  max-width: 600px;
  background: $white;

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 100%;
    background: linear-gradient(90deg, transparent 0, rgba($black, 0.1) 100%);
  }

  .info-header {
    position: relative;
    height: 36px;
    padding: 0 46px 0 0;
    width: 100%;

    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    h2 {
      display: block;
      width: 100%;
      margin: 0;
      font-size: 20px;
      line-height: 36px;
      position: relative;
      color: $black;
      font-weight: 700;
      > span {
        display: block;
        width: 100%;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close {
      width: 36px;
      height: 36px;
      padding: 10px;
      position: absolute;
      top: 50%;
      right: 0;
      margin: -18px 0 0;
      &:active {
        box-shadow: none;
      }
    }
  }

  .info-footer {
    width: 100%;
    height: 36px;
    margin: 0;
    padding: 0;

    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;
  }

  .info-body {
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 0 20px;

    -webkit-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    overflow: auto;

    > div {
      width: 100%;
      margin: 0;
      padding: 0;

      .btn-flex-group {
        &.m16 {
          margin: 16px -4px;

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .info-error {
    width: 100%;
    .wrapper-alert-inline {
      margin: 0;
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .data-row-info {
    left: calc(100% - 600px);
  }
}

// ---

.btn-flex-group {
  margin: 16px -4px;
  width: auto;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  > div {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    padding: 0 4px;
  }
  .btn {
    min-width: 100px;
  }
  .loader-wrapper {
    margin-bottom: 0;
  }
}


.btn-flex-group + .wrapper-admin-list {
  margin-top: 16px;
}

// ---

.wrapper_select_single {
  &.language-type-select {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrapper-highlighter-box {
  font-size: 14px;
  line-height: 18px;

  &:empty {
    display: none;
  }

  .copy-code {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    min-height: 216px;
    height: 216px;
    resize: vertical;
    border: 1px solid rgba($gray-400, 0.5);
    background: transparent !important;
    padding: 8px !important;

    &::-webkit-resizer {
      background-image: url('../svg/resizer/normal.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: 100% 100%;
    }

    code {
      font-size: 14px;
      line-height: 18px;
      font-family: "Nunito Sans", sans-serif;
      span {
        vertical-align: top;
      }
    }
  }
}


.wrapper-isReadOnly {
  .wrapper-input {
    p {
      display: block;
      width: 100%;
      height: auto;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: rgba($black, 0.9);
      margin: 4px 0 8px;
      padding: 8px;
      border: 1px solid transparent;
      border-radius: 4px;

      white-space: pre-line;
      word-break: break-all;

      &:first-child {
        margin-top: 0;
      }
  
      &:last-child {
        margin-bottom: 0;
      }

      &.pre {
        min-height: 216px;
        height: 216px;
        resize: vertical;
        overflow-y: auto;
        border: 1px solid rgba($gray-400, 0.5);

        &::-webkit-resizer {
          background-image: url('../svg/resizer/normal.svg');
          background-size: 8px 8px;
          background-repeat: no-repeat;
          background-position: 100% 100%;
        }
      }
    }
  }

  h3 {
    display: block;
    width: 100%;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    padding: 0;
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wrapper-checkbox {
    label {
      cursor: default;
    }
  }
}


// --- 

.table-loader {
  .table {
    table {
      tbody {
        tr:not(.no-border) {
          &:hover {
            background: $gray-100;

            td[data-sticky-td] {
              background: $gray-100;
            }
          }
        }
        
        tr[data-error-row="true"] {
          &:hover {
            background: $red-200-hover !important;

            td[data-sticky-td] {
              background: $red-200-hover !important;
            }
          }
        }

        tr[data-selected="true"] {
          &:hover {
            background: $selected-hover !important;

            td[data-sticky-td] {
              background: $selected-hover !important;
            }
          }
        }

        tr[data-disabled="true"] {
          &:hover {
            background: $gray-100-hover !important;

            td[data-sticky-td] {
              background: $gray-100-hover !important;
            }
          }
        }
      }
    }
  }
}