@import "./variables";

#root {
  height: 100%;
  .content {
    height: 100%;
    .wrapper {
      height: 100%;
    }
  }
}

.wrapper-page {
  padding: 16px 30px;

  h1 {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: $black;
    margin: 12px 0 32px;
    white-space: normal;
    word-break: break-all;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-flex-group {
    margin: 32px -4px 0;
    width: auto;
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      padding: 0 4px;
    }
  }

  .wrapper-alert-inline + .btn-flex-group {
    margin-top: 0;
  }

  h1 + .wrapper-alert-inline {
    margin-top: 16px;
    &:empty {
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  > * {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }

  > h1,
  .navigation {
    margin: 0;
  }

  > h1 + .table-loader,
  > h1 + .btn-flex-group,
  > h1 + .alert-inline {
    margin-top: 32px !important;
  }

  > h1 + .alert-inline + .btn-flex-group {
    margin-top: 0;
  }

  > .table-loader {
    margin-top: 16px;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    min-height: 136px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    position: relative;

    > .loading {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      background: rgba($white, 0.75);
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: flex-start;

      margin: 0;
      padding: 48px;
    }

    > .table {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: stretch;

      .wrapper-sticky-pagination {
        order: 0;
        flex: 1 1 auto;
        align-self: auto;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;

        > .table-responsive {
          order: 0;
          flex: 1 1 auto;
          align-self: auto;
        }

        > .pagination {
          order: 0;
          flex: 0 1 auto;
          align-self: auto;
        }
      }
    }
  }
}

.wrapper-right-menu {
  padding: 56px 0 0;
  min-height: 100%;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;

  > div:nth-child(1) {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    width: 56px;
    max-width: 56px;

    background-color: $gray-100;
  }

  > div:nth-child(2) {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    width: calc(100% - 56px);
    max-width: calc(100% - 56px);
  }

  &.expanded {
    > div:nth-child(1) {
      width: 200px;
      max-width: 200px;
    }

    > div:nth-child(2) {
      width: calc(100% - 200px);
      max-width: calc(100% - 200px);
    }
  }
}


.correction-margin {
  margin-top: 16px;
}

.wrapper-admin-list {
  align-items: stretch;
  align-self: auto;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  order: 0;
  .wrapper-admin-list-group {
    align-items: stretch;
    align-self: auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    order: 0;
  }
  .table-loader,
  .table-loader .table,
  .table-loader .table .wrapper-sticky-pagination {
    align-content: stretch;
    align-items: stretch;
    align-self: auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    order: 0;
    .table-responsive {
      align-self: auto;
      flex: 1 1 auto;
      order: 0;
    }
  }
}

.wrapper-login {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  > div {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 436px;
    max-width: 436px;
    padding: 30px;
  }
}

.wrapper-alert-inline:empty {
  margin: 8px 0;
}
